:root {
    --primary-color: #f5c34b;
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg,hsla(0,0%,100%,.15),hsla(0,0%,100%,0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0,0,0,.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #0d6efd;
    --bs-link-hover-color: #0a58ca;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
    --swiper-theme-color: #007aff;
    --swiper-navigation-size: 44px;
}

*, :after, :before {
    box-sizing: border-box;
}


body{
    background-attachment: fixed;
    background-color: #fff;
    color: #5f6973;
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    overflow-x: hidden;
    transition: inherit;
    transition: all .4s ease;
    margin: 0;
}
div {
    display: block;
    unicode-bidi: isolate;
}
section {
    display: block;
    unicode-bidi: isolate;
    padding: 20px 0;
    position: relative;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    color: #1a3760;
    font-weight: 500;
}
h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}
h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
}
a{
    text-decoration: none;
}
address, blockquote, dd, dl, dt, ol, p, ul {
    margin: 0 0 10px;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1* var(--bs-gutter-y));
    margin-right: calc(-.5* var(--bs-gutter-x));
    margin-left: calc(-.5* var(--bs-gutter-x));
}


.text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.ms-2 {
    margin-left: .5rem !important;
}
.d-inline-block {
    display: inline-block !important;
}
.btn {
    --bs-btn-padding-x: 0.75rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 1rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: #212529;
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 1px;
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 0.375rem;
    --bs-btn-hover-border-color: transparent;
    --bs-btn-box-shadow: inset 0 1px 0 hsla(0,0%,100%,.15), 0 1px 1px rgba(0,0,0,.075);
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb),.5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
a {
    color: #0d638f;
    text-shadow: none;
}
.mb30 {
    margin-bottom: 30px;
}
.bg-home1 {
    background-color: #fff;
    background-size: cover;
    height: 700px;
}
.bg-home1, .bg-img10 {
    background-position: 50%;
}
.posr {
    position: relative;
}
.m-auto {
    margin: auto !important;
}
.home_content {
    padding: 100px 0 50px 0px;
}
.home_content.home1_style .home-text, .home_content.home4_style, .home_content_home5_style {
    position: relative;
    margin-bottom: 10px;
}
.text-center {
    text-align: center !important;
}
.home_content.home1_style .home-text .title {
    color: black;
    font-size: 60px;
    font-style: oblique;
    font-weight: 600;
    line-height: 73px;
    letter-spacing: 0;
    position: relative;
    margin-bottom: 0px;
}
.home-text .h1, .home-text .h2, .home-text .h3, .home-text h1, .home-text h2, .home-text h3 {
    color: black;
    margin-top: 0;
}
h2 .main-banner{
    color: #fff;
    margin-top: 0;
    font-size: 90px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;   
    -webkit-text-stroke: 1px black;
    text-shadow:
       3px 3px 0 #000,
     -1px -1px 0 #000,  
      1px -1px 0 #000,
      -1px 1px 0 #000,
       1px 1px 0 #000;
}

.home_content.home1_style .home-text .para {
    color: #F4C430;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    text-transform: capitalize;
}
.advance_search_panel {
    display: none;
    position: relative;
    z-index: 2;
}
.justify-content-center {
    justify-content: center !important;
}
.nav-pills {
    --bs-nav-pills-border-radius: 0.375rem;
    --bs-nav-pills-link-active-color: #fff;
    --bs-nav-pills-link-active-bg: #0d6efd;
}


.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #1a3760;
    background-color: var(--bs-nav-pills-link-active-bg);
}
.nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius);
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}

.nav-link.active{
    color: #1a3760;
}

.nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}
.advance_search_panel .adss_bg_stylehome1, .advance_search_panel .adss_bg_stylehome4 {
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    width: 100%;
    box-shadow: 0 18px 60px rgba(23, 26, 33, .05);
}
.advance_search_panel .adss_bg_stylehome1 {
    max-width: 1070px;
}
.advance_search_panel .home1_advance_search_wrapper {
    padding: 30px 35px;
}
.home1_advance_search_wrapper .select-boxes {
    text-align: left;
}
.home1_advance_search_wrapper .select-boxes {
    width: 257px;
    margin: 0 6px;
}
.home1_advance_search_wrapper .form-select {
    background-color: transparent;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    color: #5f6973;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    height: 50px;
    letter-spacing: 0;
    line-height: 35px;
    text-align: left;
}
.d-block {
    display: block !important;
}
.btn-thm, .btn-thm.rounded {
    background-color: var(--primary-color);
    color: #1a3760;
}
.btn-thm {
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    transition: all .3s ease;
}
.advnc_search_form_btn {
    background-color: var(--primary-color);
    -o-box-shadow: 0 7px 20px rgba(245, 195, 75, .2);
    box-shadow: 0 7px 20px rgba(245, 195, 75, .2);
    border-radius: 8px;
    color: #1a3760;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    height: 50px;
    line-height: 16px;
    letter-spacing: 0;
    padding: 0 40px;
}
.list-inline-item:not(:last-child) {
    margin-right: .5rem;
}
.list-inline-item {
    display: inline-block;
}

.home-one {
    margin-top: -126px;
}
.mt10 {
    margin-top: 10px;
}
.p0 {
    padding: 0 !important;
}
.ovh {
    overflow: hidden;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-right: auto;
    margin-left: auto;
}

.main-banner-wrapper {
    position: relative;
}

.swiper-horizontal {
    touch-action: pan-y;
}
.swiper, swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    display: block;
}
.swiper-android .swiper-slide, .swiper-wrapper {
    transform: translateZ(0);
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
}
.swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.swiper-slide, swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
    display: block;
}
.home2_main_slider .banner-style-one .slide {
    padding: 135px 60px;
}
.banner-style-one .slide {
    padding: 165px 0;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
}
.banner-style-one .slide:before {
    background-color: transparent;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}

.main-banner-wrapper .carousel-btn-block {
    cursor: pointer;
    position: absolute;
    top: 45%;
    width: 100%;
    z-index: 9
}
.home-content-home2-style {
    padding: 110px;
    position: relative;
}
.banner-style-one .swiper-slide-active .banner-btn, .banner-style-one .swiper-slide-active .banner-title, .banner-style-one .swiper-slide-active .banner_top_title, .banner-style-one .swiper-slide-active .property_details, .banner-style-one .swiper-slide-active .sliding-box-object, .home-content-home6-style .banner-title, .home-content-home6-style .banner_top_title {
    animation-name: fadeInUp;
    animation-delay: .3s;
}
.banner-style-one .banner-title, .banner-style-one .banner_top_title, .banner-style-one .banner_top_title .small, .banner-style-one .banner_top_title small, .home-content-home6-style .banner-title, .home-content-home6-style .banner_top_title {
    color: #fff;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 73px;
    animation-duration: 2s;
    animation-fill-mode: both;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
}
.banner-style-one .banner_top_title {
    color: var(--primary-color);
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 73px;
    letter-spacing: 0;
    position: relative;
}
.text-thm {
    color: var(--primary-color) !important;
}
.align-items-baseline {
    align-items: baseline !important;
}
.d-flex {
    display: flex !important;
}
span.aminated-object1 .objects {
    bottom: 0;
    position: absolute;
    width: 170px;
    animation: jumpTwo 5s linear infinite;
}
span.aminated-object2 .objects {
    bottom: 0;
    position: relative;
    left: 1%;
    animation: jumpTwo 5s linear infinite;
}
span.aminated-object3{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

img {
    max-width: 100%;
}
img, svg {
    vertical-align: middle;
}
img {
    overflow-clip-margin: content-box;
    overflow: clip;
}
.home-content-home6-style .banner-btn, .home2_main_slider .banner-style-one .banner-btn, .home2_main_slider .banner-style-one.active .banner-btn {
    color: #1a3760;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    padding: 16px 40px;
}
.banner-style-one .banner-btn {
    background-color: #fff;
    border-radius: 8px;
    border: none;
    color: #1a3760;
    transition: all .3s ease 0s;
    margin-top: 20px;
    animation-duration: 2s;
    animation-fill-mode: both;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
}
.swiper-backface-hidden .swiper-slide {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.swiper-slide, swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
    display: block;
}
.main-banner-wrapper .carousel-btn-block .carousel-btn {
    background-color: hsla(0,0%,100%,.1);
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    transition: all .3s ease;
    vertical-align: middle;
    z-index: 9
}

.main-banner-wrapper .carousel-btn-block .carousel-btn:hover {
    background-color: #fff;
    color: #5f6973;
    opacity: 1
}

.main-banner-wrapper .carousel-btn-block .carousel-btn.left-btn {
    position: absolute;
    left: 30px
}

.main-banner-wrapper .carousel-btn-block .carousel-btn.right-btn {
    position: absolute;
    right: 30px
}

.banner-style-one .slide {
    padding: 165px 0;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover
}

.banner-style-one .slide:before {
    background-color: transparent;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}
.main-banner-wrapper .carousel-btn-block .carousel-btn.left-btn {
    position: absolute;
    left: 30px;
}
.main-banner-wrapper .carousel-btn-block .carousel-btn.right-btn {
    position: absolute;
    right: 30px;
}
.main-banner-wrapper .carousel-btn-block .carousel-btn {
    background-color: hsla(0, 0%, 100%, .1);
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    transition: all .3s ease;
    vertical-align: middle;
    z-index: 9;
}
.pb100 {
    padding-bottom: 10px;
}

.bgc-f9 {
    background-color: #f9f9f9;
}
[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
    transition-delay: .1s;
}
[data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    transform: translateZ(0);
}
[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
    transition-delay: 0;
}
[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
    transition-timing-function: ease;
}
[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
    transition-duration: 1.2s;
}
[data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity, transform;
}
.category_item {
    border: 1px solid #eaeaea;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 30px 0 25px;
    position: relative;
    text-align: center;
    transition: all .3s ease;
}
.logo_item{
    background-color: transparent;
    border-radius: 8px;
    margin-bottom: 0px;
    padding: 20px 0 15px;
    position: relative;
    text-align: center;
    transition: all .3s ease;
}
.logo_item .thumb img {
    transform: scale(1);
    transition: all .3s ease;
}
.logo_item .thumb {
    margin-bottom: 40px;
    overflow: hidden;
    padding: 0 45px;
    position: relative;
}
.category_item .thumb {
    margin-bottom: 40px;
    overflow: hidden;
    padding: 0 45px;
    position: relative;
}
.category_item .details .title, .category_item .details .title a {
    color: #1a3760;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    margin-bottom: 0;
}
.category_item .thumb img {
    transform: scale(1);
    transition: all .3s ease;
}
@media(max-width: 575px) {
    .category_item .thumb {
        padding:0
    }
}
.logo-category{
    background-color: #eaeaea
}
.category_item:hover {
    box-shadow: 0 18px 60px rgba(255, 255, 255, 0.05)
}

.category_item:hover .thumb img {
    transform: scale(1.1)
}
.logo_item:hover .thumb img {
    transform: scale(1.1)
}
.pb25 {
    padding-bottom: 25px;
}

.pt50 {
    padding-top: 50px;
}
.footer_one {
    background-color: black;
}
.footer_about_widget, .footer_contact_widget {
    margin-bottom: 30px;
}
.copyright-widget, .footer_about_widget, .footer_contact_widget, .footer_menu_widget {
    position: relative;
}
.mb40 {
    margin-bottom: 40px !important;
}
.mt15 {
    margin-top: 15px;
}
.copyright-widget, .footer_about_widget, .footer_contact_widget, .footer_menu_widget {
    position: relative;
}
.footer_menu_widget ul li a {
    margin-right: 27px
}

.footer_menu_widget ul li:last-child a {
    margin-right: 0
}

.footer_menu_widget ul li a {
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0
}
.footer_one hr {
    border-top: 1px solid #fff
}

.footer_one .hr {
    border-top: 1px solid rgba(0,0,0,.35)
}
.pb70 {
    padding-bottom: 70px;
}

.pt80 {
    padding-top: 80px;
}
.footer_about_widget, .footer_contact_widget {
    margin-bottom: 30px;
}
.footer_about_widget .title, .footer_contact_widget .title {
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
}
.footer_about_widget p, .footer_contact_widget p {
    color: hsla(0, 0%, 100%, .7);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
}
.footer_contact_widget .footer_phone {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
}
.footer_mailchimp_form {
    margin-bottom: 15px;
    position: relative;
}
.footer_mailchimp_form .col-auto {
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}
.footer_mailchimp_form button {
    background-color: var(--primary-color);
    border-radius: 0 8px 8px 0;
    border: none;
    color: #1a3760;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    height: 50px;
    line-height: 10px;
    letter-spacing: 0;
    outline: none;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;
}
.copyright-widget p {
    color: hsla(0, 0%, 100%, .7);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    margin-bottom: 0;
}
.copyright-widget p a {
    color: hsla(0, 0%, 100%, .7) !important;
}
.mt5 {
    margin-top: 5px;
}
.footer_social_widget {
    position: relative;
}

@media (min-width: 768px) {
    .text-md-end {
        text-align: right !important;
    }
}
.footer_social_widget li {
    background-color: hsla(0, 0%, 100%, .07);
    border-radius: 50%;
    height: 40px;
    line-height: 40px;
    position: relative;
    text-align: center;
    width: 40px;
    transition: all .3s ease 0s;
}
.footer_social_widget li a {
    color: #fff;
    font-family: Font Awesome\ 6 Brands;
    font-size: 12px;
    line-height: 14px;
    transition: all .3s ease 0s;
}
.pb90 {
    padding-bottom: 90px;
}
.main-title {
    position: relative;
    margin-bottom: 50px;
}
.main1-title {
    margin-top: 10px;
    position: relative;
    margin-bottom: 10px;
}
.main-title .h2, .main-title h2, .main1-title .h2, .main1-title h2 {
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: 0;
    margin-top: 0;
}
.main1-title h2{
    color: #000;
}
.pt0 {
    padding-top: 50 !important;
}

.why_chose_us.home7_style {
    border-radius: 16px;
    padding: 70px 80px;
    text-align: center;
}
.why_chose_us {
    margin-bottom: 30px;
    position: relative;
    transition: all .3s ease;
}
.why_chose_us.home7_style .icon {
    display: inline-block;
    margin: 0 0 40px;
}
.why_chose_us .icon {
    background-image: url();
    height: 80px;
    line-height: 80px;
    margin-right: 30px;
    text-align: center;
    width: 90px;
    transition: all .3s ease;
}
.why_chose_us .icon span {
    display: block;
    transition: all .3s ease;
}
.why_chose_us.home7_style .details {
    width: auto;
}
.why_chose_us .details .title, .why_chose_us .details .title a {
    color: #1a3760;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0;
}
.why_chose_us .details p {
    color: #5f6973;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    margin-bottom: 0;
}
.why_chose_us .icon span {
    color: #c8666c;
    font-size: 40px;
}
.why_chose_us .icon span {
    display: block;
    transition: all .3s ease;
}

.filter_buttons{
 display: flex;
 -webkit-box-align: center;
 -webkit-box-pack: center;
 align-items: center;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 gap: 1.25rem;
 width: 100%;
 -webkit-margin-start: auto;
 margin-inline-start: auto;
 -webkit-margin-end: auto;
 margin-inline-end: auto;
 margin-top: 0.5rem;
}
@media screen and (min-width: 62em) {
    .filter_buttons{
        width: 70%;
        flex-direction: row;
    }
}
.button-47 {
    display: inline-flex;
    justify-content: center;
    appearance: none;
    position: relative;
    align-items: center;
    background: #FFFFFF;
    border: 1 solid rgb(23,25,35);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    color: rgb(23,25,35);    
    font-family: Inter, sans-serif;
    font-size: 1rem;
    font-weight: 700;
    height: 2.5rem;    
    line-height: 1.2;
    overflow-wrap: break-word;
    text-decoration: none;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 70%;
    vertical-align: middle;
    outline: 2px solid transparent;
    outline-offset: 2px;
    min-width: 2.5rem;
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .button-47:hover{
    color:#FFFFFF;
    background-color:rgb(23,25,35);  
  }

  @media screen and (min-width: 62em) {
    .button-47{
      width: 30%;
    }
}

@media only screen and (max-width: 767.98px) {
    .home_content.home1_style .home-text .title {
        font-size: 25px;
        line-height: 46px;
        margin-bottom: 0px;
        color: rgb(23,25,35);
        font-weight: 100;
    }
    h2 .main-banner{
        font-size: 36px;
        line-height: 50px;
        color: rgb(23,25,35);
        font-weight:normal;
        text-shadow: none;
        margin-bottom: 30px;
    }
    .home_content.home1_style .home-text .title span img {
        width: 150px;
        height: 90px;
        margin-bottom: 20px;
    }
    .home_content.home1_style .home-text .title span img .objects1 {
        display: none;

    }
    span.aminated-object1 .objects {
        bottom: 0;
        position: absolute;
        display:none;
        animation: jumpTwo 5s linear infinite;
    }
    .advance_search_panel .home1_advance_search_wrapper {
        padding: 30px 15px;
    }
    .home1_advance_search_wrapper .select-boxes {
        width: 100%;
        margin: 0;
    }
    .main-title {
        margin-bottom: 0px;
    }
}

@media only screen and (max-width: 520px) {
    .bg-home1 {
        height: 420px;
    }
    .advance_search_panel .adss_bg_stylehome1 {
        border-radius: 0;
        box-shadow: none;
    }
    .home_content.home1_style .home-text .para{
        color: #F4C430;
        font-family: 'Playfair Display';
        font-size: 15px;
        margin-bottom: 0px;
      }
  
}
@media only screen and (max-width: 992px) {
    .home-one {
        margin-top: 0;
    }
}



@media (min-width: 1200px) {
    .col-xl-12 {
        flex: 0 0 auto;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        width: 100%;
    }
    .col-xl {
        flex: 1 0;
    }
    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }
    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }
}

@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1320px;
    }
}
@media (min-width: 992px) {
    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }
}
@media (min-width: 992px) {
    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}
@media (min-width: 768px) {
    .text-md-end {
        text-align: right !important;
    }


}
@media (min-width: 768px) {
    .text-md-start {
        text-align: left !important;
    }
}