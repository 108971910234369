.b1 {
    background-color: green;
    font-size: 10px;
    color: white;
    text-align: center;
    display: inline-block;
    border: none;
    padding: 16px 32px;
}
.b2 {
    background-color: yellow;
    font-size: 10px;
    color: white;
    text-align: center;
    display: inline-block;
    border: none;
    padding: 32px 64px;
}